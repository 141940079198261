import React, { useState } from "react";

// Libraries
import { connect } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

// Core
import { setNotification } from "../../../../core/notifications";
import { getUid } from "../../../../core/session";
import { db } from "../../../../core/firebase";

// Components
import Button from "../../buttons";

import { IconRight, IconSpin } from "../../icons";

function Container({ toggle, setNotification, uid }) {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [meedenken, setMeedenken] = useState("");
	const [confirmed, setConfirmed] = useState(false);
	const [loading, setLoading] = useState(false);

	const submitForm = async () => {
		setLoading(true);

		const response = await db
			.collection("meedenken")
			.doc(uid)
			.set({
				email,
				name,
				meedenken,
				confirmed,
				createdAt: new Date(),
			})
			.then((doc) => ({ doc }))
			.catch((error) => ({ error }));

		setLoading(false);

		if (response.error) {
			setNotification({
				error: true,
				message:
					"Er is een fout opgetreden bij het opslaan van de informatie.",
			});
		} else {
			setNotification({
				message: "Het formulier is succesvol verstuurd!",
			});
			toggle();
		}
	};

	return (
		<div className="container container-m">
			<h3 className="pt-l fs-m a-l mb-s">Naam</h3>
			<input
				className="input"
				placeholder="Naam"
				value={name}
				type="text"
				onChange={(e) => setName(e.target.value)}
			/>
			<h3 className="fs-m a-l mb-s mt">Emailadres</h3>
			<input
				className="input"
				placeholder="Emailadres"
				value={email}
				type="email"
				onChange={(e) => setEmail(e.target.value)}
			/>

			<h3 className="fs-m a-l mb-s mt">Ik wil graag meedenken over:</h3>
			<textarea
				className="textarea"
				placeholder="Ik wil graag meedenken over"
				value={meedenken}
				type="text"
				onChange={(e) => setMeedenken(e.target.value)}
			/>

			<div className="mt flex">
				<input
					type="checkbox"
					id="avg"
					name="avg"
					onChange={() => setConfirmed(!confirmed)}
					value={confirmed}
				/>
				<label className="ml c-gray" htmlFor="avg">
					Ik stem toe dat de Gemeente Apeldoorn mijn naam en e-mailadres opslaat en mij mag benaderen om mee te denken over de thema’s.
				</label>
			</div>
			<Button
				className="mt-m"
				text="Versturen"
				onClick={submitForm}
				disabled={!email.length || !name.length || !confirmed}
			>
				{loading ? (
					<IconSpin className="ml icon icon-sm spin" />
				) : (
					<IconRight className="ml icon icon-sm" />
				)}
			</Button>
		</div>
	);
}

const state = createSelector(getUid, (uid) => ({ uid }));
export default connect(state, { setNotification })(Container);
